/*******************************
         Site Overrides
*******************************/
body {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

/* Main Container and wrapper */
@media(min-width: 768px) {
  .wrapper {
      min-height: auto;
      margin-top: 82px;
  }

  .ui.container {
    margin-top: 82px;
    padding-left: 200px !important;
  }
}

.ui.container {
  padding-left: 20px;
  padding-right: 20px;
  height: calc(90vh - 25px);
  overflow: auto;
}

/* Menu Overrides */
.ui.labeled.icon.menu .item {
  text-align: left;
}

.ui.thin.left.sidebar, .ui.thin.right.sidebar {
  width: 200px !important;
}

.ui.labeled.vertical.icon.menu .item>.icon:not(.dropdown) {
  height: 1em;
  display: inline-block !important;
  margin: 0 !important;
  margin-right: 10px !important;
}

.ui.positive.buttons .button, .ui.positive.button {
  background-color: var(--primary) !important;
}
